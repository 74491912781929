<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="42.759"
    height="35.422"
    viewBox="0 0 42.759 35.422"
  >
    <title>Arrow Left</title>
    <g transform="translate(42.759 35.422) rotate(180)"><rect
    width="36.112"
    height="6.019"
    transform="translate(0 15.017)"
    fill="#e1001e"
  /><path
    d="M25.047,25.047H0v-7.3H17.742V0h7.3V25.046Z"
    transform="translate(7.337 17.711) rotate(-45)"
    fill="#e1001e"
  /></g></svg>
</template>
